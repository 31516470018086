import React, { useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import Img from 'gatsby-image';

const MenuPageLayout = styled.div`
  margin: 0 1rem 1rem 1rem;
  display: flex;
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    flex-direction: column-reverse;
  }
  .menu {
    height: 100%;
    width: 100%;
    max-width: 900px;
  }
`;

const StyledMenuNav = styled.div`
  text-align: left;
  margin: 6vh 0;
  position: relative;
  button {
    background: none;
    border: none;
    outline: none;
    text-transform: uppercase;
    font-size: 4vh;
    margin: 0;
    text-align: left;
    cursor: pointer;
    outline-color: transparent;
    outline-style: solid;
    outline-width: 0 0 3px 0;
    outline-offset: -1vh;
    padding: 20px 2vh;
    transition: all 0.3s linear 0.1s;
    letter-spacing: 2px;
    &:hover {
      color: ${props => props.theme.white()};
      background: ${props => props.theme.navy()};
    }
  }
  button.selected {
    outline-color: ${props => props.theme.navy()};
    &:hover {
      outline-color: transparent;
    }
  }
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    margin: 3rem 0;
  }
`;

const MenuNavigation = ({ handleChange, selected, options }) => {
  return (
    <StyledMenuNav>
      {options.map((option, index) => (
        <>
          <button
            key={option.dataKey}
            type="button"
            className={selected === option.dataKey ? 'selected' : ''}
            onClick={() => handleChange(option.dataKey)}
          >
            {option.linkText}
          </button>
          {index < options.length - 1 && <br />}
        </>
      ))}
    </StyledMenuNav>
  );
};

const fadeInThenSwipeLeft = keyframes`
  0% {
    opacity: 0%;
    right: 0;
  };
  45% {
    opacity: 100%;
    right: 0;
  };
  /*  Slight break in animation to allow for state change while fully covered */
  55% {
    opacity: 100%;
    right: 0;
  };
  100% {
    opacity: 100%;
    right: 100%;
  };
`;

const Transition = styled.div`
  position: absolute;
  top: 0;
  right: 100%;
  height: 100%;
  width: 100%;
  opacity: 0;
  background: white;
  animation: ${fadeInThenSwipeLeft} 0.95s 1 linear;
`;

const menusLinkTextAndDataKey = [
  { linkText: 'Lunch', dataKey: 'LUNCH' },
  { linkText: 'Dinner', dataKey: 'DINNER' },
  { linkText: 'Steak Night', dataKey: 'STEAKNIGHT' },
  { linkText: 'Drinks', dataKey: 'BEVERAGE' },
  { linkText: 'Happy Hour', dataKey: 'HAPPYHOUR' },
  { linkText: 'Whiskeys', dataKey: 'WHISKEYS' },
  { linkText: 'Spirits', dataKey: 'SPIRITS' },
];

const Menu = ({ data }) => {
  const [selectedMenu, setSelectedMenu] = useState('SPIRITS');
  const [transitioning, setTransitioning] = useState(false);

  const handleMenuChange = menu => {
    setTransitioning(true);
    setTimeout(() => setSelectedMenu(menu), 500);
    setTimeout(() => setTransitioning(false), 1000);
  };

  return (
    <MenuPageLayout>
      <div className="menu">
        <Img
          fluid={data[selectedMenu].childImageSharp.fluid}
          alt="Menu"
          objectFit="contain"
        />
        {transitioning && <Transition />}
      </div>
      <div className="navigation">
        <MenuNavigation
          selected={selectedMenu}
          handleChange={menu => handleMenuChange(menu)}
          options={menusLinkTextAndDataKey}
        />
      </div>
    </MenuPageLayout>
  );
};

export default Menu;
