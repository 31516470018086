import React from 'react';
import Page from '../components/Page';
import Menu from '../components/Menu';
import WipeTransition from '../components/WipeTransition';

export const query = graphql`
  query {
    LUNCH: file(relativePath: { eq: "menus2024Q1/lunch.webp" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    DINNER: file(relativePath: { eq: "menus2024Q1/dinner.webp" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    STEAKNIGHT: file(relativePath: { eq: "menus2024Q1/steakNight.webp" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    BEVERAGE: file(relativePath: { eq: "menus2024Q1/beverage.webp" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    HAPPYHOUR: file(relativePath: { eq: "menus2024Q1/happyHour.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    WHISKEYS: file(relativePath: { eq: "menus2024Q1/whiskeys.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SPIRITS: file(relativePath: { eq: "menus2024Q1/spirits.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const MenuPage = ({ transitionStatus, data }) => {
  return (
    <Page lightBackground noHeader>
      <meta name="theme-color" content="#000000" />
      <Menu data={data} />
      {transitionStatus === 'entering' && (
        <WipeTransition animation="finishRightToLeft" />
      )}
    </Page>
  );
};

export default MenuPage;
